// override global styles here
@import './fonts';
@import './reset';
@import './semantic-ui-overrides';
@import './ng2-daterangepicker-overrides';
@import './ng-select-overrides';
@import './adyen-overrides';
@import './destygo-chat-overrides';
@import 'variables';
@import 'libs/ui-kit/src/scss/05-objects/ghost';

.gc-tooltip {
  max-height: 99.9%;
}

.layout-content {
  background-color: $background;
  overflow-x: hidden !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.clickable {
  cursor: pointer;
}

.form-section-title {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
}

.form-section-sub-title {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(51, 51, 51, 0.6);
  margin: 10px 0;
}

.font-section-description {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
}

.icon-wrapper {
  display: none;
  flex-shrink: 0;
  margin-right: 10px;
  opacity: 0.5;
}

.actions-column {
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  .ui.dropdown {
    width: 55px;

    .menu {
      left: auto !important;
      right: 0 !important;
      min-width: 200px;
    }

    .button {
      font-size: 20px !important;
      background: none !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

@include media('<desktop') {
  .ui.table:not(.unstackable) {
    tr {
      position: relative;

      & > td,
      & > th {
        &.id-column {
          display: none !important;
        }
      }
    }
  }
}

@include media('<tablet') {
  .ui.table {
    .icon-wrapper {
      display: block;
    }
  }

  .ui.table:not(.unstackable) {
    thead {
      display: none !important;
    }

    tr {
      position: relative;

      & > th {
        &.status-column {
          display: none !important;
        }
      }

      & > td {
        &.main-column,
        .main-cell-text {
          font-size: 18px;
          font-weight: bold;
        }

        &.main-column {
          padding-left: 0.5em !important;
        }

        &.client-name-column {
          opacity: 0.5;
        }

        &.status-column {
          position: absolute;
          top: 14px;
          right: 0;
        }

        &.price-column {
          position: absolute;
          top: 14px;
          right: 0;
        }

        &.actions-column {
          position: absolute;
          top: 0;
          right: -25px;
          height: 100%;

          .ui.dropdown {
            top: 0;

            .button {
              padding-top: 15px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }

    &.with-actions {
      td {
        width: calc(100% - 50px) !important;

        &.status-column {
          width: auto !important;
          right: 31.5px;
        }

        &.actions-column {
          width: auto !important;
        }
      }
    }

    .mobile-column-label {
      opacity: 0.5;
    }
  }
}

.disabled-link {
  display: block;
  pointer-events: none;
  opacity: 0.7;
}

.uppy-StatusBar {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
}

.dimmed {
  color: rgba(0, 0, 0, 0.3);
}

@include media('<tablet') {
  .tablet-only,
  .computer-only {
    display: none !important;
  }

  .mobile-only {
    display: initial !important;
  }
}

@include media('>=tablet') {
  .mobile-only {
    display: none !important;
  }

  .tablet-only,
  .computer-only {
    display: initial !important;
  }
}

@include media('>=desktop') {
  .mobile-only,
  .tablet-only {
    display: none !important;
  }

  .computer-only {
    display: initial !important;
  }
}

.ghost-active {
  color: transparent;
  position: relative;
  &:before {
    @include animate-ghost-line;
    @include background-gradient;
    content: '';
    position: absolute;
    height: 12px;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);
    border-radius: 8px;
    z-index: 5;
  }
}

.ghost-active-fluid {
  position: relative;
  &:before {
    @include animate-ghost-line;
    @include background-gradient;
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
  }

  &.line {
    height: 20px;
    margin-bottom: 25px;
  }
  &.xxl {
    width: 80%;
  }
  &.xl {
    width: 60%;
  }
  &.l {
    width: 50%;
  }
  &.m {
    width: 40%;
  }
  &.s {
    width: 20%;
  }
}

.ng-clear-wrapper {
  top: 10px;
  right: 14px;
  z-index: 101;
}

.uppy-DashboardContent-panel .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox {
  display: none !important;
}

gd-splash-message {
  .message {
    ul {
      margin: 0 !important;
    }
  }
}

ng-select.profit-calculator-select {
  .ng-select-container {
    height: 64px;
  }

  .ng-value,
  .ng-option {
    display: flex !important;
    align-items: center;
  }

  .ng-value-label {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: _spacing(1);
  }
}
