form {
  label {
    display: block;
    font-size: $font-size-xs;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px; //to update base unit to 4
  }

  input,
  textarea {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .mat-checkbox,
  .mat-radio-button {
    label {
      margin: 0;
    }
  }
}

.options-group {
  .mat-checkbox,
  .mat-radio-button {
    display: block;
    margin-bottom: 8px;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family;
}

textarea {
  resize: none;
}
