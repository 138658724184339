/***  Headings ***/
$h1-color: $black800;
$h2-color: $black800;
$h3-color: $black800;
$h4-color: $black800;

$h1: (
  'color': $h1-color,
  'line-height': $font-size-l + 6,
  'size': $font-size-l,
  'weight': 700,
);

$h2: (
  'color': $h2-color,
  'line-height': $font-size-m + 6,
  'size': $font-size-m,
  'weight': 600,
);

$h3: (
  'color': $h3-color,
  'line-height': $font-size-sm + 6,
  'size': $font-size-m,
  'weight': 600,
);

$h4: (
  'color': $h4-color,
  'line-height': $font-size-s + 6,
  'size': $font-size-m,
  'weight': 600,
);

/***  Body copy ***/
$body-color: $black700;
$body-copy: (
  'color': $body-color,
  'line-height': $font-size-s + 6,
  'size': $font-size-s,
  'weight': 400,
);

/***  Links ***/
$link-color: $brand500;
$link-hover-color: $brand800;
$link-color-disabled: $black400;
