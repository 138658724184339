$base-color: #f0f0f1;
$ghost-color: #ecebebf1;
$animation-duration: 1000ms;
$gradient-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $ghost-color 40px, $base-color 80px);
  background-size: 80vw;
}

@mixin animate-ghost-line {
  animation: ghost-lines $animation-duration infinite linear;
}

@mixin ghost-active {
  color: transparent;
  position: relative;
  &:before {
    @include animate-ghost-line;
    @include background-gradient;
    content: '';
    position: absolute;
    height: 12px;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes ghost-lines {
  0% {
    background-position: -100px;
  }
  40% {
    background-position: 40vw;
  }
  100% {
    background-position: 60vw;
  }
}
