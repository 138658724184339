@import '../../../../../node_modules/include-media/dist/include-media';

.custom-overlay-backdrop {
  background: rgba(10, 31, 68, 0.7);
}

.deck-panel {
  position: fixed !important;
  left: 0;
  right: 0;

  .deck-wrapper {
    .header-wrapper .content {
      padding-right: 50px;
    }

    .order-summary-segment .summary-wrapper {
      max-width: 100%;
      padding: 0;
    }
  }
}

// Temp changes to Sui modal
@include media('>=tablet') {
  .ui.modal.tiny.fullscreen {
    width: 510px !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}

@include media('<tablet') {
  .ui.modal.tiny.fullscreen {
    border-radius: 0;
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto !important;
  }
}
