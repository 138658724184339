@import '../01-settings/color-palette';
@import '../01-settings/settings.global';

gc-checkbox,
gd-checkbox {
  .ui.checkbox {
    input:checked ~ label:before {
      background: $primary100 !important;
    }

    label:before,
    ~ label:before {
      border: 1px solid $primary100 !important;
    }
  }
}
