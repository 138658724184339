html {
  font-size: 14px;
}

html,
body {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: $black;
}

* {
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
}
