@import '../01-settings/color-palette';
@import '../01-settings/settings.global';

gd-radio-button,
gc-radio-button {
  .ui.radio.checkbox input,
  .ui.radio.checkbox input ~ label {
    & ~ .box,
    & ~ label {
      &:before {
        border: 1px solid $primary100 !important;
      }

      &:after {
        background-color: $primary100 !important;
      }
    }
  }
}
