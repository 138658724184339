@import '../01-settings/color-palette';

.ngx-toastr {
  z-index: 100;

  .toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: 700;
    color: #76312e;
    text-shadow: 0 1px 0 $white;
  }
}
