@import '../01-settings/color-palette';
@import '../01-settings/settings.global';

.ui.button {
  font-size: $font-size-s;
  border-radius: 6px !important;
  box-shadow: none !important;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &.link {
    font-weight: bold !important;
    background: transparent !important;
    border: none !important;

    &,
    &:focus {
      color: $brand500 !important;
    }

    &:hover {
      color: #679aff !important;
    }

    &:active {
      color: #4785ff !important;
    }
  }

  &.secondary {
    &,
    &:focus {
      color: $primary100;
      background: $white;
      border: 2px solid $primary100;
    }

    &:hover {
      color: $primary100;
      background: $background;
    }

    &:active {
      color: $primary100;
      background: $background;
    }
  }

  &.secondary-grey {
    &,
    &:focus {
      color: $black800;
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }

    &:hover {
      color: $black800;
      background: rgba(89, 145, 255, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.3);
    }

    &:active {
      color: $black800;
      background: $white;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }

  &.primary {
    &,
    &:focus {
      background: $primary100 !important;
    }

    &:hover {
      background: $neutral90 !important;
    }

    &:active {
      background: $neutral50 !important;
    }
  }

  &.negative {
    &,
    &:focus {
      background: $red500;
      border: 2px solid $red500;
      color: $white;
    }

    &:hover {
      background: #f26663;
      border: 2px solid #f26663;
      box-shadow: 0px 2px 7px rgba(240, 74, 71, 0.24), 0px 3px 8px rgba(240, 74, 71, 0.35) !important;
    }

    &:active {
      background: #f15855;
      border: 2px solid #f15855;
      box-shadow: none !important;
    }

    &.basic {
      &,
      &:focus {
        color: #ea3d2f !important;
        background: $white !important;
        border: 2px solid rgba(234, 61, 47, 0.7);
        box-shadow: none !important;
      }

      &:hover {
        color: #f6675b !important;
        background: rgba(246, 103, 91, 0.05) !important;
        border: 2px solid rgba(247, 103, 91, 0.7);
        box-shadow: none !important;
      }

      &:active {
        color: #e51f0f !important;
        background: $white !important;
        border: 2px solid rgba(230, 31, 15, 0.7);
        box-shadow: none !important;
      }
    }
  }

  &.orange {
    &.basic {
      &,
      &:focus {
        color: $white !important;
        background: $orange600 !important;
        border: none !important;
        box-shadow: none !important;
      }

      &:hover {
        color: $white !important;
        background: rgba(255, 138, 0, 0.9) !important;
        border: none !important;
        box-shadow: none !important;
      }

      &:active {
        color: $white !important;
        background: rgba(255, 138, 0, 0.9) !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  &.gold {
    &,
    &:focus {
      color: $white !important;
      background: $gold !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:hover {
      color: $white !important;
      background: $goldAlpha80 !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:active {
      color: $white !important;
      background: $goldAlpha80 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  &.purple {
    &,
    &:focus {
      color: $white !important;
      background: $purple !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:hover {
      color: $white !important;
      background: $purpleAlpha80 !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:active {
      color: $white !important;
      background: $purpleAlpha80 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
}
