@mixin ani-spin($loop-duration: 3000ms) {
  animation-name: spin;
  animation-duration: $loop-duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@mixin shine($color: $blue, $size: 6px, $type: stay, $time: 400ms, $direction: outset) {
  animation-name: shine-out-#{str-slice(#{$color}, 2)}-#{$size}-#{$direction};
  animation-duration: $time;
  @if $type == pulse {
    animation-iteration-count: infinite;
    animation-direction: alternate;
  } @else if $type == once {
    animation-iteration-count: 2;
    animation-direction: alternate;
  } @else if $type == stay {
    animation-fill-mode: forwards;
  } @else {
    animation-fill-mode: none;
  }
  @include shine-ani($color: $color, $size: $size, $direction: $direction);
}

@mixin shine-ani($color: $blue, $size: 6px, $direction: inset) {
  @keyframes shine-out-#{str-slice(#{$color}, 2)}-#{$size}-#{$direction} {
    @if $direction != inset {
      $direction: '';
    }
    0% {
      box-shadow: #{$direction} 0 0 0 0 rgba($color, 0), #{$direction} 0 0 0 0 rgba($color, 0);
    }
    100% {
      box-shadow: #{$direction} 0 0 $size rgba($color, 0.3), #{$direction} 0 0 0 $size rgba($color, 0.1);
    }
  }
}
