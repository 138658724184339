@import 'node_modules/include-media/dist/_include-media.scss';
@import 'libs/ui-kit/src/scss/overrides';

.ui.dropdown {
  width: 100%;

  & > .text {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .menu {
    & > .item {
      color: #333333 !important;

      &:hover {
        background: rgba(0, 216, 193, 0.11) !important;
      }

      &.item-action-delete {
        color: #ea3d2f !important;
      }
    }
  }
}

.ui.selection.dropdown {
  min-width: auto !important;
}

.ui.checkbox {
  & ~ label,
  label {
    color: #333333 !important;

    &:before {
      border: 1px solid #367bf5 !important;
      background: #fff !important;
    }

    &:after {
      color: #fff !important;
    }
  }

  input:checked ~ label:before {
    background: #367bf5 !important;
  }

  &.partial {
    input:checked ~ label:after {
      content: '\e801' !important;
      top: 1px !important;
    }
  }
}

.ui.radio.checkbox input,
.ui.radio.checkbox input ~ label {
  & ~ .box,
  & ~ label {
    &:before {
      border: 1px solid #367bf5 !important;
      background: #fff !important;
    }

    &:after {
      background-color: #367bf5 !important;
    }
  }
}

.ui.input,
textarea {
  &[readonly] {
    background: #f4f6f8 !important;
  }
}

.ui.form {
  label {
    color: #333333 !important;
    font-weight: normal !important;
  }

  &.loading:before {
    background: rgba(244, 246, 248, 0.8);
  }

  @include media('<tablet') {
    .fields {
      .field {
        margin-bottom: 1em !important;
      }

      &:last-child {
        .field:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.ui.accordion .title {
  color: #333333 !important;
}

.ui.table {
  thead th {
    font-weight: 500 !important;
  }
  color: #333333 !important;

  a {
    color: inherit;

    &:hover {
      color: #367bf5;
      text-decoration: underline;
    }

    @include media('<tablet') {
      display: block;
      width: 100%;
      word-break: break-word;
    }
  }

  .ui.dropdown {
    a:hover {
      text-decoration: none;
    }
  }

  td {
    color: #333333 !important;
    font-size: 14px;
  }

  &.selectable {
    tbody tr:hover {
      background: rgba(0, 216, 193, 0.11) !important;
      color: #5e6366 !important;
    }
  }

  &.very.basic {
    thead tr {
      top: none;

      th {
        border-bottom: 2px solid #e7e9eb !important;
      }
    }

    @include media('>tablet') {
      th,
      td {
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }

    @include media('<tablet') {
      &:not(.unstackable) th {
        border-bottom: none !important;
      }
    }

    tbody tr {
      border-bottom: 1px solid #e7e9eb !important;
      box-shadow: none !important;

      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}

.ui.segment {
  box-shadow: 8px 14px 32px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  border: none;

  &.box-shadow-new {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.04);
  }

  &.loading {
    min-height: 80px;
    color: inherit !important;
  }
}

.ui.table {
  &.box-shadow-new {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.04);
    border: none;
  }
}

.ui.divider {
  border-top: none !important;
  border-bottom: 1px solid #e7e9eb !important;
}

.ui.label {
  font-weight: normal !important;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.ui.stackable.grid {
  @include media('<tablet') {
    & > .row > .column,
    & > .row > .wide.column {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.ui.message {
  &.info {
    background: #ebf2ff;
    box-shadow: 0 0 0 1px #5991ff inset, 0 0 0 0 transparent;
    color: #5991ff;
  }

  &.warning {
    background: #fff5e6;
    box-shadow: 0 0 0 1px #e18700 inset, 0 0 0 0 transparent;
    color: #e18700;
  }
}

.ui.toggle.checkbox {
  input {
    &,
    &:focus {
      & ~ .box:before,
      & ~ label:before {
        border: none !important;
      }
    }
  }

  label {
    &:before,
    &:after {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.ui.toggle.checkbox.green {
  label:before {
    border: 1px solid #d4d4d5 !important;
  }

  input {
    &,
    &:focus {
      & ~ .box:before,
      & ~ label:before {
        background: #78909c;
        border-radius: 34px;
        border: none !important;
        height: 14px;
        margin: 3px 0;
      }

      & ~ .box:after,
      & ~ label:after {
        background: #f7f9fa;
        box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
    }
  }

  input:checked {
    &,
    &:focus {
      & ~ .box:before,
      & ~ label:before {
        background: linear-gradient(90deg, #cff37d 0%, #7de88c 100%);
        border-radius: 34px;
        border: none !important;
        height: 14px;
        margin: 3px 0;
      }

      & ~ .box:after,
      & ~ label:after {
        background: #2fa84f;
        box-shadow: 0px 2px 4px rgba(47, 168, 79, 0.24), 0px 4px 8px rgba(47, 168, 79, 0.16);
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.ui.button {
  &.with-svg-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-height: 40px;

    > img {
      margin-right: 6px;
    }
  }
}

.ui.inverted.dimmer.transparent {
  background-color: transparent !important;
}

.message.notification {
  .message {
    h5 {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 6px;
      opacity: 1;
    }

    p {
      font-size: 12px;
      line-height: 14px;
      margin: 0;
      opacity: 1;
    }
  }
}

.ui.modal {
  & > .close {
    top: 12px !important;
    right: 12px !important;
    background: url(/assets/icons/close.svg);
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;

    &:before {
      content: none;
    }
  }

  .header {
    padding: 12px 48px 12px 24px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500 !important;
    font-size: 21px !important;
    line-height: 28px !important;
  }

  .content {
    padding: 24px !important;
  }

  .actions {
    border-top: none;
    padding: 12px !important;
    background: #fff;
    text-align: right;

    > .button {
      margin-bottom: 0;
    }
  }

  @include media('<tablet') {
    .header {
      padding: 12px 48px 12px 12px !important;
    }

    .content {
      padding: 12px !important;
    }
  }
}

.ui.accordion.expansion-faq {
  box-shadow: none;

  .title {
    padding: 0.75em 6px;
    border: none;
    font-weight: normal;

    &.active {
      font-weight: bold;
      background: rgba(34, 34, 34, 0.05);
    }

    &:hover {
      cursor: pointer;
      background: rgba(34, 34, 34, 0.05);
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      .dropdown.icon {
        margin-left: 16px;
      }
    }
  }

  .content {
    padding-left: 6px;
  }

  .content.active {
    background: rgba(34, 34, 34, 0.05);
  }
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;

  &.adyen-checkout__applepay__button {
    -webkit-appearance: -apple-pay-button;
  }
}
