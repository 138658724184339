@import 'color-palette';
@import 'old_colors-palette';

/***  Global constants ***/
$base-spacing-unit: 8px;
$border-normal: 1px;
$border-thin: 0.7px;
$border-radius: 4px;
$border-radius-m: 6px;
$border-radius-l: 8px;

/***  Font family ***/
$font-family: system-ui !important;

/***  Font sizes ***/
$font-size-huge: 64px;
$font-size-massive: 48px;
$font-size-xxxl: 42px;
$font-size-xxl: 32px;
$font-size-xl: 24px;
$font-size-l: 20px;
$font-size-m: 18px;
$font-size-sm: 16px;
$font-size-s: 14px;
$font-size-xs: 12px;
$font-size-xxs: 9px;

/***  Brand colours ***/
$brand: $brand500;
$info: $brand200;
$success: $green500;
$warning: $yellow500;
$danger: $red500;
