@import './libs/ui-kit/src/scss/variables';

.custom-buttons-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;

  .ui.button {
    flex: 1;
    font-size: $font-size-sm;
    font-weight: normal;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7) !important;
    background: none;
    border: 1px solid transparent;
    border-radius: 3px !important;
    padding: _spacing(0.25) _spacing(2) !important;
    margin-right: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      background: #f5f5f5;
    }
    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: rgba(89, 145, 255, 0.1);
      border-color: #5991ff;
    }
    .subtitle {
      color: $black600;
      font-size: $font-size-xs;
    }
  }
}
