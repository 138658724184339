// Dropdown overrides

.retail-price {
  .currency {
    .ng-select .ng-arrow-wrapper .ng-arrow {
      padding: 0;
    }

    .ng-select .ng-select-container .ng-value-container .ng-input > input {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
