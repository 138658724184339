.adyen-checkout__button--pay {
  display: none;
}

.adyen-checkout__payment-method {
  border: none !important;
  background: none !important;
}

.adyen-checkout__payment-method__header {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.adyen-checkout__payment-method__details {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.adyen-checkout__payment-method__details__content {
  margin-bottom: 0 !important;
}

.adyen-checkout__label__text {
  color: #5e6366 !important;
  font-size: 13px !important;
  line-height: 1.4285em !important;
  margin-bottom: 4px !important;
  padding-bottom: 0 !important;
}

.adyen-checkout__input {
  &,
  &:hover,
  &:active {
    border-color: rgba(34, 36, 38, 0.15) !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }

  &--focus,
  &:focus {
    color: rgba(0, 0, 0, 0.95) !important;
    border-color: #85b7d9 !important;
    box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset !important;
  }

  &--error,
  &--invalid {
    background: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
  }
}

.adyen-checkout__error-text {
  margin-top: 5px !important;
  font-size: 12px !important;
  color: #9f3a38 !important;
}

.adyen-checkout__fieldset__title {
  height: 24px;
  visibility: hidden;
}
