.overlay-container {
  .bold {
    font-weight: bold;
  }

  .toast-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 60px;
    padding-right: 14px;
    opacity: 1;
    pointer-events: inherit;

    .validation-message {
      min-width: 480px;
    }

    &.top-center {
      justify-content: center !important;
      padding: 0 !important;
      margin-top: 65px !important;
    }

    .ui.message {
      background: #fff;

      &.success {
        background: #fcfff5;
        color: #2c662d;
        padding-right: 40px;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-attachment: inherit;
        background-position: 98% 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.54541 11L9.18177 14.6364' stroke='%2363A312' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.18188 14.6365L16.4546 7.36375' stroke='%2363A312' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='11' cy='11' r='10' stroke='%2363A312' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &.info {
        background: #eef4ff;
        color: #5991ff;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-attachment: inherit;
        background-position: 98% 50%;
        padding-right: 40px;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 9.75L11 16' stroke='%235991FF' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 6L11 7.25' stroke='%235991FF' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='11' cy='11' r='10' stroke='%235991FF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &.error {
        background: #fff6f6;
        color: #9f3a38;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .overlay-container .toast-container {
    &.top-center {
      width: 90% !important;
      margin-left: 5% !important;
    }

    .validation-message {
      min-width: 300px;
    }
  }
}
