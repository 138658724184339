@import 'mixin/mixin';

.ng-select {
  .ng-select-container {
    box-sizing: border-box;
    background: #fff;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
    width: 100%;
    min-height: 2.71428571em;
    overflow: visible;
  }

  &.fit-content {
    .ng-dropdown-panel {
      width: auto !important;
      max-width: 550px;
      min-width: 100%;
      right: -2px;
    }
  }
  @include media('<tablet') {
    &.fit-content {
      .ng-dropdown-panel {
        width: 100% !important;
      }
    }
  }

  .ng-value-container {
    position: relative;
  }

  .ng-placeholder,
  .ng-value {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0.67857143em 2.1em 0.67857143em 1em;
  }

  .ng-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    input {
      box-sizing: border-box !important;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0.67857143em 2.1em 0.67857143em 1em;
    }
  }

  .ng-placeholder {
    color: #c8c8c8;
    z-index: 1;
  }

  .ng-value {
    z-index: 2;
  }

  .ng-input {
    z-index: 3;
  }

  .ng-arrow {
    display: inline-block !important;
    cursor: pointer;
    position: absolute;
    width: auto !important;
    height: auto !important;
    line-height: 1.21428571em;
    top: 0.78571429em;
    right: 1em;
    z-index: 3;
    margin: -0.78571429em;
    padding: 0.91666667em;
    opacity: 0.8;
    -webkit-transition: opacity 0.1s;
    transition: opacity 0.1s ease;

    font-family: Dropdown;
    font-size: 12px;
    width: auto;
    height: auto;

    &:before {
      content: '\f0d7';
    }
  }

  &.ng-select-multiple {
    .ng-value-container {
      padding: 3px 20px 3px 5px;
    }

    .ng-value {
      @include select-label;
    }

    .ng-input {
      z-index: 0 !important;
    }
  }
  &.show-all {
    .ng-placeholder {
      color: #333333;
    }
  }

  &.ng-select-disabled {
    opacity: 0.45;
  }

  &.ng-select-opened {
    .ng-value {
      color: #c8c8c8;
    }

    .ng-select-container {
      border-radius: 4px 4px 0 0;
      border-color: #96c8da;
      border-bottom: 1px solid #fff;
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    }

    .ng-dropdown-panel-items {
      border-top: none;
      border-color: #96c8da;
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    }
  }

  &.ng-select-top.ng-select-opened {
    .ng-select-container {
      border-radius: 0 0 6px 6px;
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #96c8da;
    }

    .ng-dropdown-panel.ng-select-top {
      bottom: 40px;
    }

    .ng-dropdown-panel-items {
      border-bottom: none;
      border-radius: 6px 6px 0 0;
      border-top: 1px solid #96c8da;
      box-shadow: none;
    }
  }

  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  .ng-dropdown-panel-items {
    width: calc(100% + 2px) !important;
  }

  .ng-dropdown-header {
    background-color: #fff;
    border-top: none;
    border-bottom: none;
    cursor: pointer;

    .header-content {
      padding: 11px 16px;
      display: flex;

      img {
        width: 13px;
        margin-right: 6px;
      }
    }

    &:hover {
      background: rgba(0, 216, 193, 0.11) !important;
    }
  }

  &.hide-tags {
    .ng-value {
      display: none;
    }
    .ng-placeholder {
      display: block;
      white-space: nowrap;
    }
  }

  &.phone-codes-dropdown .ng-dropdown-panel {
    min-width: 300px;
  }
}

.ng-dropdown-panel {
  background: #fff;
  font-size: 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);

  .ng-option {
    cursor: pointer;
    border-top: 1px solid #fafafa;
    padding: 0.78571429rem 1.14285714rem !important;
    white-space: normal;
    word-wrap: normal;
    font-size: 1rem;

    &:hover {
      background: rgba(0, 216, 193, 0.11) !important;
    }
  }

  .ng-option-selected {
    font-weight: bold;
  }

  .ng-option-selected,
  .ng-option-marked {
    background: rgba(0, 0, 0, 0.03);
  }

  .ng-option-disabled {
    cursor: default;
    pointer-events: none;
  }
}

.field.error {
  .ng-select {
    .ng-select-container {
      background: #fff6f6;
      border-color: #e0b4b4;
      color: #9f3a38;
    }

    .ng-placeholder {
      color: #e7bdbc;
    }

    .ng-input {
      input {
        background: none !important;
      }
    }
  }
}

.tag {
  @include select-label;
}
