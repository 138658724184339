/* Define the "system" font family */
@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 300;
  src: local('.SFNSDisplay-Light'), local('.HelveticaNeueDeskInterface-Light'), local('.LucidaGrandeUI'),
    local('Segoe UI Light'), local('Ubuntu Light'), local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 300;
  src: local('.SFNSDisplay-LightItalic'), local('.HelveticaNeueDeskInterface-Italic'), local('.LucidaGrandeUI'),
    local('Segoe UI Light Italic'), local('Ubuntu Light Italic'), local('Roboto-LightItalic'), local('DroidSans'),
    local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 400;
  src: local('.SFNSDisplay-Regular'), local('.HelveticaNeueDeskInterface-Regular'), local('.LucidaGrandeUI'),
    local('Segoe UI'), local('Ubuntu'), local('Roboto-Regular'), local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 400;
  src: local('.SFNSDisplay-Italic'), local('.HelveticaNeueDeskInterface-Italic'), local('.LucidaGrandeUI'),
    local('Segoe UI Italic'), local('Ubuntu Italic'), local('Roboto-Italic'), local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 500;
  src: local('.SFNSDisplay-Medium'), local('.HelveticaNeueDeskInterface-MediumP4'), local('.LucidaGrandeUI'),
    local('Segoe UI Semibold'), local('Ubuntu Medium'), local('Roboto-Medium'), local('DroidSans-Bold'),
    local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 500;
  src: local('.SFNSDisplay-MediumItalic'), local('.HelveticaNeueDeskInterface-MediumItalicP4'), local('.LucidaGrandeUI'),
    local('Segoe UI Semibold Italic'), local('Ubuntu Medium Italic'), local('Roboto-MediumItalic'),
    local('DroidSans-Bold'), local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 600;
  src: local('.SFNSDisplay-Semibold'), local('.HelveticaNeueDeskInterface-Semibold'), local('.LucidaGrandeUI'),
    local('Segoe UI Semibold'), local('Ubuntu Semibold'), local('Roboto-Semibold'), local('DroidSans-Semibold'),
    local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 600;
  src: local('.SFNSDisplay-SemiboldItalic'), local('.HelveticaNeueDeskInterface-SemiboldItalic'),
    local('.LucidaGrandeUI'), local('Segoe UI Semibold Italic'), local('Ubuntu Semibold Italic'),
    local('Roboto-SemiboldItalic'), local('DroidSans-Semibold'), local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 700;
  src: local('.SFNSDisplay-Semibold'), local('.HelveticaNeueDeskInterface-Semibold'), local('.LucidaGrandeUI'),
    local('Segoe UI Semibold'), local('Ubuntu Semibold'), local('Roboto-Semibold'), local('DroidSans-Semibold'),
    local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 700;
  src: local('.SFNSDisplay-SemiboldItalic'), local('.HelveticaNeueDeskInterface-SemiboldItalic'),
    local('.LucidaGrandeUI'), local('Segoe UI Semibold Italic'), local('Ubuntu Semibold Italic'),
    local('Roboto-SemiboldItalic'), local('DroidSans-Semibold'), local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 800;
  src: local('.SFNSDisplay-Bold'), local('.HelveticaNeueDeskInterface-Bold'), local('.LucidaGrandeUI'),
    local('Segoe UI Bold'), local('Ubuntu Bold'), local('Roboto-Bold'), local('DroidSans-Bold'), local('Tahoma Bold');
}

@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 800;
  src: local('.SFNSDisplay-BoldItalic'), local('.HelveticaNeueDeskInterface-BoldItalic'), local('.LucidaGrandeUI'),
    local('Segoe UI Bold Italic'), local('Ubuntu Bold Italic'), local('Roboto-BoldItalic'), local('DroidSans-Bold'),
    local('Tahoma Bold');
}
/* Now, let's apply it on an element */
html {
  font-family: system-ui !important;
}
