.date-range-picker {
  .md-drppicker.double {
    width: max-content;
    top: 57px !important;
  }

  .md-drppicker .ranges ul li button.active {
    background-color: #fcf3d7;
    color: #333;
  }

  .md-drppicker td.active,
  .md-drppicker td.active:hover {
    background-color: #367bf5;
  }

  .md-drppicker .btn {
    background: rgb(89, 145, 255);
    border: 2px solid rgb(89, 145, 255);
    box-shadow: 0 2px 7px #5991ff3d, 0 3px 8px #5991ff59 !important;
    margin: 0 0.25em 0 0;
    border-radius: 6px !important;
    font-size: 14px;
    font-weight: 700;

    &.btn-default {
      color: #5991ff;
      background: #ffffff;
      border: 2px solid rgba(89, 145, 255, 0.48);
      box-shadow: none !important;
    }
  }
}
