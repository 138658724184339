/***  Primary colours ***/
$brand50: #f5faff;
$brand100: #ebf2ff;
$brand200: #cddeff;
$brand300: #acc8ff;
$brand400: #72a2ff;
$brand500: #5991ff;
$brand600: #487eff;
$brand700: #3e74ff;
$brand800: #2e62ff;

$pink-link: #e32776;
$brand-pink-link: #e5468c;
$brand-pink-button-border: $brand-pink-link;
$brand-pink-button-background: #fbe3ee;

$black100: #f4f4f4;
$black200: #e6e6e6;
$black300: #d6d6d6;
$black400: #b3b3b3;
$black500: #989898;
$black600: #858585;
$black700: #4d4d4d;
$black800: #333333;
$black900: #000000;
$black900Alpha10: rgba($black900, 0.1);
$black900Alpha20: rgba($black800, 0.2);
$black900Alpha80: rgba($black900, 0.8);
$black900Alpha85: rgba($black900, 0.85);
$black800Alpha10: rgba(51, 51, 51, 0.1);
$black800Alpha40: rgba(51, 51, 51, 0.4);
$black800Alpha50: rgba(51, 51, 51, 0.5);
$black800Alpha60: rgba(51, 51, 51, 0.6);
$black800Alpha70: rgba(0, 0, 0, 0.7);
$black800Alpha5: rgba(34, 34, 34, 0.05);
$black800Alpha3: rgba(0, 0, 0, 0.3);
$black800Alpha03: rgba(0, 0, 0, 0.03);

/***  Green colours ***/
$green100: #f0fce0;
$green400: #72ba5a;
$green500: #63a312;
$androidGreen: #95bf47;
$success50: #9cd8ca;
$success70: #4d9334;

/*** Teal colours ***/
$teal400: #189a7b;
$teal500: #00a7a3;
$crayola: #189a7b;

/*** Orange colours ***/
$orange100: #fff5e5;
$orange200: #ffe1a7;
$orange300: #d48c06;
$orange400: #efa30f;
$orange500: #e18700;
$orange600: #ff8a00;
$orange700: #ad5c00;
$deepCarrotOrange: #e16c35;
$destructive50: #feb6a3;

/*** Yellow colours ***/
$yellow500: #f3dc0a;

/*** Gold colours ***/
$gold: #c6a13be6;
$gold50: #fdd877;
$goldAlpha80: rgba(198, 161, 59, 0.8);

/*** Brown colours ***/
$brown500: #956133;

/*** Red colours ***/
$red100: #fff3f3;
$red400: #fd5f5c;
$red500: #f04a47;
$red600: #d0412d;

/*** Blue colors ***/
$blue100: #eef4ff;
$blue200: #dee9ff;
$blue400: #7aa7ff;
$blue500: #5991ff;
$blue600: #4774cc;
$blue700: #355799;
$info500: #6395df;
$info70: #5178b4;

/*** Gray colors ***/
$gray100: #e3e5e6;

/*** Purple colors ***/
$purple: #9c77ac;
$purpleAlpha20: rgba(156, 119, 172, 0.2);
$purpleAlpha80: rgba(156, 119, 172, 0.8);
$purple-primary90: #542c65;

/***  Aux colours ***/
$white: #ffffff;
$whiteAlpha10: rgba($white, 0.1);
$whiteAlpha0: rgba($white, 0);
$whiteAlpha20: rgba($white, 0.2);
$whiteAlpha90: rgba($white, 0.9);
$transparent: transparent;

$banana20: #fff4d7;
$banana70: #cba543;
$banana100: #463710;
$ube20: #ebddf1;
$separator: #e7e9eb;
$background: #f5f6f8;
$discountLabelBackground: #ec3646;
$formFieldErrorColor: #9f3a38;
$neutral10: #f2f2f2;
$neutral20: #e6e6e6;
$neutral30: #d4d4d4;
$neutral40: #bdbdbd;
$neutral50: #999999;
$neutral70: #6b6b6b;
$neutral80: #525252;
$neutral90: #383838;

$primary100: #212121;

$info30: #aac0ec;
$info50: #eef1fb;
