.daterangepicker {
  color: rgba(0, 0, 0, 0.7);

  &.dropdown-menu {
    display: none;
    position: absolute;
    box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
    border-radius: 6px;
    border: none;
  }

  .daterangepicker_input {
    display: none;
  }

  .btn-success {
    &,
    &:focus {
      background: rgba(89, 145, 255, 1);
      border: 2px solid rgba(89, 145, 255, 1);
      box-shadow: 0px 2px 7px rgba(89, 145, 255, 0.24), 0px 3px 8px rgba(89, 145, 255, 0.35) !important;
      color: #fff !important;
    }

    &:hover {
      background: #679aff;
      border: 2px solid #679aff;
      box-shadow: 0px 4px 7px rgba(89, 145, 255, 0.24), 0px 3px 8px rgba(89, 145, 255, 0.35) !important;
    }

    &:active {
      background: #4785ff;
      border: 2px solid #679aff;
      box-shadow: none !important;
    }
  }

  .btn-default {
    &,
    &:focus {
      color: #5991ff;
      background: #fff;
      border: 2px solid rgba(89, 145, 255, 0.48);
    }

    &:hover {
      color: #679aff;
      background: rgba(89, 145, 255, 0.05);
      border: 2px solid rgba(103, 154, 255, 0.7);
    }

    &:active {
      color: #4785ff;
      background: #ffffff;
      border: 2px solid rgba(71, 133, 255, 0.7);
    }
  }

  .fa,
  .glyphicon {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .fa-chevron-left,
  .glyphicon-chevron-left {
    &:before {
      content: '\f053';
    }
  }

  .fa-chevron-right,
  .glyphicon-chevron-right {
    &:before {
      content: '\f054';
    }
  }

  .table-condensed {
    border-spacing: 0;
    border-collapse: collapse;

    td {
      height: 32px;

      &.in-range {
        background-color: #e6efff;
      }

      &.start-date {
        border-radius: 50% 0 0 50%;
      }

      &.end-date {
        border-radius: 0 50% 50% 0;
      }

      &.active {
        &,
        &:hover {
          background-color: #367bf5;
        }
      }
    }
  }

  .ranges {
    border: none !important;
    background: #ffffff;

    ul {
      width: auto !important;
      min-height: 245px;

      li {
        background: none;
        border: none;
        border-left: 1px solid #e3e5e6;
        box-sizing: border-box;
        border-radius: 0;
        height: 37px;
        padding: 0 25px;
        margin: 0;
        line-height: 37px;
        color: #919699;

        &:hover,
        &:active,
        &.active {
          background: #fcf3d7;
          border-left: 1px solid #f3aa18;
          color: #333333;
        }
      }
    }

    border: 1px solid #e3e5e6;
    box-sizing: border-box;
    border-radius: 2px;
  }
}

@include media('<tablet') {
  .daterangepicker {
    .drp-buttons > button {
      width: 48%;
      margin-top: 10px;
    }
  }
}
