// Styles for Sanity output markup

.marketing-auth-title {
  i {
    opacity: 0.8;
  }
}

.marketing-auth-content {
  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-flex;
      font-size: $font-size-m;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: _spacing(9) - 2;
      padding-right: _spacing(9) - 2;
      width: 280px;
    }
  }
}

.sanity-dashboard-slot {
  h4 {
    color: $black800;
    font-size: $font-size-l;
    line-height: 20px;
    margin: _spacing(0.5) + 1 0 _spacing(2) + 4;
  }

  p {
    color: $black800;
    font-size: $font-size-s;
    font-weight: 300;
    line-height: 20px;
    margin: 0 0 _spacing(1) + 2;
  }

  a {
    color: $brand500;
    cursor: pointer;
    font-size: $font-size-s;
    line-height: 20px;
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }
}
