@mixin select-label() {
  display: flex;
  flex-direction: row-reverse;
  position: static;
  width: auto;
  height: auto;
  background: #e8e8e8;
  border-radius: 0.28571429rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.35714286em 0.78571429em;
  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;

  .ng-value-icon {
    cursor: pointer;
    opacity: 0.5;
    margin-left: 6.5px;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);

    &:hover {
      opacity: 1;
    }
  }
}
